import React from 'react';
import s from "./styles.module.css";

const TextArea = ({onChange, value, errorMessage, placeholder, style, name}) => {
    return (
        <div>
        <textarea rows={3} style={style} className={s.input} name={name} value={value} placeholder={placeholder}
                  onChange={onChange}/>
            <span className={s.errorMessage}>{errorMessage}</span>
        </div>


    );
};

export default TextArea;
