import React from 'react';
import Wrapper from "../../components/wrapper";
import Title from "../../components/title";
import s from './styles.module.css'
import pdf1 from '../../assets/pdf/Политика_обработки_персональных_данных_ООО_«АЙТЕМБАЙ».pdf'
import pdf2 from '../../assets/pdf/Согласие_на_обработку_персональных_данных_ООО_«АЙТЕМБАЙ».pdf'
import pdf3 from '../../assets/pdf/Согласие_на_получение_рекламных_рассылок_ООО_«АЙТЕМБАЙ».pdf'
import Item from "./item";

const mock = [
    {file: pdf1, title: 'Политика обработки персональных данных', id: 1},
    {file: pdf2, title: 'Согласие на обработку персональных данных', id: 2},
    {file: pdf3, title: 'Согласие на получение рекламных рассылок', id: 3},
]

const DocumentsRoots = () => {
    return (
        <div className={s.document_roots}>
            <Wrapper>
                <Title title={'Документы и правила'}/>

                <div className={s.box}>
                    {mock.map((el) => <Item key={el.id} {...el}/>)}
                </div>
            </Wrapper>
        </div>
    );
};

export default DocumentsRoots;
