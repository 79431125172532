import React from 'react';

const LogoText = ({style, color = '#222C3E', className, onClick}) => {
    return (
        <svg onClick={onClick} className={className} style={style} width="115" height="18" viewBox="0 0 115 18"
             fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M43.4527 7.13374L48.3727 15.224H50.1247L55.0711 6.91652L55.0927 17H58.7407L58.6927 0.199951H55.5007L49.3135 10.6316L43.0207 0.199951H39.8047V17H43.4527V7.13374Z"
                fill={color}/>
            <path fillRule="evenodd" clipRule="evenodd"
                  d="M76.4306 15.8C75.2946 16.6 73.6226 17 71.4146 17H62.7266V0.199951H70.9346C73.0466 0.199951 74.6306 0.599951 75.6866 1.39995C76.7586 2.19995 77.2946 3.25595 77.2946 4.56795C77.2946 5.44795 77.0786 6.21595 76.6466 6.87195C76.2556 7.45115 75.7336 7.91241 75.0806 8.25573C75.2386 8.30853 75.3926 8.36727 75.5426 8.43195C76.3586 8.76795 76.9906 9.27195 77.4386 9.94395C77.9026 10.616 78.1346 11.44 78.1346 12.416C78.1346 13.856 77.5666 14.984 76.4306 15.8ZM73.4066 10.448C72.8946 10.096 72.1346 9.91995 71.1266 9.91995H66.5906V14.072H71.1266C72.1346 14.072 72.8946 13.912 73.4066 13.592C73.9346 13.256 74.1986 12.728 74.1986 12.008C74.1986 11.304 73.9346 10.784 73.4066 10.448ZM72.6146 3.63195C72.1186 3.29595 71.3986 3.12795 70.4546 3.12795H66.5906V7.08795H70.4546C71.3986 7.08795 72.1186 6.92795 72.6146 6.60795C73.1266 6.27195 73.3826 5.76795 73.3826 5.09595C73.3826 4.43995 73.1266 3.95195 72.6146 3.63195Z"
                  fill={color}/>
            <path
                d="M82.9501 15.296C84.2941 16.624 86.1581 17.288 88.5421 17.288C90.9261 17.288 92.7901 16.624 94.1341 15.296C95.4781 13.968 96.1501 12.072 96.1501 9.60795V0.199951H92.3101V9.46395C92.3101 11.064 91.9821 12.216 91.3261 12.92C90.6701 13.624 89.7501 13.976 88.5661 13.976C87.3821 13.976 86.4621 13.624 85.8061 12.92C85.1501 12.216 84.8221 11.064 84.8221 9.46395V0.199951H80.9341V9.60795C80.9341 12.072 81.6061 13.968 82.9501 15.296Z"
                fill={color}/>
            <path
                d="M114.35 0.199951L107.822 11.0517V17H103.934V11.0118L97.4298 0.199951H101.558L106.036 7.65781L110.534 0.199951H114.35Z"
                fill={color}/>
            <path d="M0.992188 0.199951V17H4.88019V0.199951H0.992188Z" fill={color}/>
            <path d="M12.3472 17V3.36795H6.97119V0.199951H21.6112V3.36795H16.2352V17H12.3472Z" fill={color}/>
            <path
                d="M27.5671 9.99195V13.88H36.7111V17H23.7031V0.199951H36.3991V3.31995H27.5671V6.96795H35.3671V9.99195H27.5671Z"
                fill={color}/>
        </svg>

    );
};

export default LogoText;
