import React, {useEffect, useState} from 'react';
import s from './styles.module.css'
import Wrapper from "../../../components/wrapper";
import classNames from "classnames";
import {ReactComponent as ThunderSmall} from "../../../assets/thunder_small.svg";
import {ReactComponent as ThunderBig} from "../../../assets/thunder_big.svg";
import axios from "axios";
import Input from "../../../components/input";
import {Formik} from "formik";
import Button from "../../../components/button";
import {toast, ToastContainer} from "react-toastify";
import file1 from '../../../assets/pdf/Политика_обработки_персональных_данных_ООО_«АЙТЕМБАЙ».pdf'
import file2 from '../../../assets/pdf/Согласие_на_обработку_персональных_данных_ООО_«АЙТЕМБАЙ».pdf'
import file3 from '../../../assets/pdf/Согласие_на_получение_рекламных_рассылок_ООО_«АЙТЕМБАЙ».pdf'
import {ReactComponent as VkIcon} from "../../../assets/vk.svg";
import {ReactComponent as TgIcon} from "../../../assets/tg.svg";

import vkS_icon from "../../../assets/vk_tg/vk_s.svg";
import vkM_icon from "../../../assets/vk_tg/vk_m.svg";
import vkB_icon from "../../../assets/vk_tg/vk_b.svg";

import tgB_icon from "../../../assets/vk_tg/tg_b.svg";
import tgM_icon from "../../../assets/vk_tg/tg_m.svg";
import tgS_icon from "../../../assets/vk_tg/tg_s.svg";

import {ReactSVG} from "react-svg";

const HeaderMain = () => {
    const [textError, setTextError] = useState(null)

    const handleSubscribe = async (email, setSubmitting, resetForm) => {
        setSubmitting(true)
        try {
            const res = await axios.get(`https://itembuy.tech/public/api/send-email?email=${email}`)
            setSubmitting(false)
            resetForm()
            toast.success('Вы подписались!', {
                position: "top-center",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "colored",
            });

        } catch (e) {
            console.log(e)
            if (e.response.status === 409) {
                setTextError('Ваша почта уже привязана')
            } else {
                toast.error(<>Ошибка подписки.<br/> Попробуйте еще раз</>, {
                    position: "top-center",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "colored",
                });
            }
            setSubmitting(false)
        }
    }
    console.log(textError)
    return (<div className={s.header_main} id={'subscribe'}>


        <div className={s.icons_bottom}>
            <div className={s.icons_bottom_left}>
                <ReactSVG src={vkB_icon} className={s.icon_b}/>
                <ReactSVG src={tgS_icon} className={classNames(s.icon_s, s.icon_s_left)}/>
            </div>

            {/*<ReactSVG src={vkM_icon} className={s.icon_m}/>*/}
            {/*<ReactSVG src={tgM_icon} className={s.icon_m}/>*/}
            <div className={s.icons_bottom_left}>
                <ReactSVG src={vkS_icon} className={classNames(s.icon_s, s.icon_s_right)}/>
                <ReactSVG src={tgB_icon} className={classNames(s.icon_b)}/>
            </div>
        </div>
        {/*<div className={s.thunder_small}>*/}
        {/*    <ThunderSmall/>*/}
        {/*</div>*/}
        {/*<div className={s.thunder_middle}>*/}
        {/*    <ThunderBig/>*/}
        {/*</div>*/}
        {/*<div className={s.thunder_big}>*/}
        {/*    <ThunderBig/>*/}
        {/*</div>*/}
        <Wrapper>
            <div className={s.header_main_content}>
                <h1 className={s.start}>Запуск платформы <br/> совсем скоро</h1>
                <p className={s.descriptoin}>Подпишитесь, чтобы первым узнать о запуске</p>

                {/*<Formik*/}
                {/*    enableReinitialize={true}*/}
                {/*    initialValues={{*/}
                {/*        email: ''*/}
                {/*    }}*/}
                {/*    validate={values => {*/}
                {/*        const errors = {};*/}
                {/*        if (!values.email) {*/}
                {/*            errors.email = 'Обязательное поле';*/}
                {/*        } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)) {*/}
                {/*            errors.email = 'Неверный email адресс';*/}
                {/*        }*/}
                {/*        return errors;*/}
                {/*    }}*/}
                {/*    onSubmit={(values, {*/}
                {/*        setSubmitting,*/}
                {/*        resetForm,*/}

                {/*    }) => handleSubscribe(values.email, setSubmitting, () => resetForm({}))}>*/}
                {/*    {({values, handleChange, touched, handleSubmit, errors, isSubmitting, handleBlur}) => {*/}

                {/*return*/}
                <div className={s.subscribe_box}>
                    <a href="https://vk.ru/itembuy" target={'_blank'}>
                        <div className={s.btn_social}>
                            <div className={classNames(s.btn_social_icon, s.btn_social_icon_v2)}>
                                <VkIcon/>
                            </div>
                            <p className={s.btn_social_title}>ВКонтакте</p>
                        </div>
                    </a>
                    <a href="https://t.me/itembuy" target={'_blank'}>
                        <div className={s.btn_social}>
                            <div className={s.btn_social_icon}>
                                <TgIcon/>
                            </div>
                            <p className={s.btn_social_title}>Telegram</p>
                        </div>
                    </a>
                    {/*<Input onBlur={handleBlur} onChange={(e) => {*/}
                    {/*    handleChange(e)*/}
                    {/*    setTextError(null)*/}
                    {/*}}*/}
                    {/*       errorMessage={(errors.email && touched.email && errors.email) || textError}*/}
                    {/*       value={values.email} placeholder={'Введите e-mail'} name={'email'}*/}
                    {/*/>*/}

                    {/*<div className={s.btn}>*/}
                    {/*    <Button style={{minWidth: '181px'}} disabled={isSubmitting}*/}
                    {/*            type={'submit'}>Подписаться</Button>*/}
                    {/*</div>*/}
                </div>
                {/*    }}*/}
                {/*</Formik>*/}

                {/*<p className={classNames(s.descriptoin, s.descriptoin_2)}>*/}
                {/*    Нажимая на кнопку, вы соглашаетесь с*/}
                {/*    <a*/}
                {/*        href={file1}*/}
                {/*        target="_blank"*/}
                {/*        rel="noreferrer"*/}
                {/*    >*/}
                {/*        <b> политикой обработки персональных данных</b>*/}
                {/*    </a>*/}
                {/*    , даете*/}
                {/*    <a*/}
                {/*        href={file2}*/}
                {/*        target="_blank"*/}
                {/*        rel="noreferrer"*/}
                {/*    >*/}
                {/*        <b> согласие на обработку персональных данных</b>*/}
                {/*    </a> и*/}
                {/*    <a*/}
                {/*        href={file3}*/}
                {/*        target="_blank"*/}
                {/*        rel="noreferrer"*/}
                {/*    >*/}
                {/*        <b> получение рекламных рассылок</b>*/}
                {/*    </a>.*/}
                {/*</p>*/}

            </div>
        </Wrapper>
    </div>);
};

export default HeaderMain;
