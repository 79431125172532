import React from 'react';
import s from './styles.module.css'
import AlsoBlogs from "./also_blogs";


const BlogItemTop = ({img}) => {
    return (
        <div className={s.content}>
            <div className={s.img_box}>
                <img src={img} alt=""/>
            </div>

            <div className={s.also_blogs}>
                <AlsoBlogs/>
            </div>
        </div>
    );
};

export default BlogItemTop;
