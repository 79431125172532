import React from 'react';
import s from './styles.module.css'
import classNames from "classnames";
import {Box, CircularProgress} from "@mui/material";

const Button = ({children, style, type = 'button', disabled = false}) => {
    return (
        <button style={style} type={type} disabled={disabled} className={classNames(s.btn, disabled && s.disabled)}>
            {disabled ? <Box sx={{display: 'flex'}}>
                <CircularProgress/>
            </Box> : children}
        </button>
    );
};

export default Button;
