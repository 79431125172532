import React, {useState} from 'react';
import s from './styles.module.css'
import {ReactComponent as Icon} from "../../../assets/modal_1.svg";
import {ReactComponent as Close} from "../../../assets/close.svg";
import classNames from "classnames";
import Input from "../../input";
import InputMaskNumber from "../../input_mask";
import CustomSelect from "../../select";
import LoadBox from "../../load_box";
import FooterModal from "../../custom_modal/footer_modal";
import Button from "../../button";
import {Formik} from "formik";
import axios from "axios";
import {toast} from "react-toastify";

const options = [
    {value: 1, label: 'Инженер технической поддержки'},
    {value: 2, label: 'Бэкенд-разработчик'},
    {value: 3, label: 'Менеджер направления- автомобили'},
    {value: 4, label: 'Менеджер направления- недвижимость'},
    {value: 5, label: 'Бизнес-аналитик'},
];

const ModalWorkUs = ({onClose}) => {

    const handleSend = async (values, setSubmitting) => {
        setSubmitting(true)
        try {
            const res = await axios.post(`https://itembuy.tech/public/api/send-vacancy`, {
                vacancy: values.vacancy.label,
                contact_person: values.contact_person,
                phone: values.phone,
                email: values.email,
                filename: values.file_data?.filename || '',
                file_value: values.file_data?.file_value || '',
            })
            setSubmitting(false)
            onClose()
            toast.success('Ваша заявка отправлена!', {
                position: "top-center",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "colored",
            });

        } catch (e) {
            console.log(e)
            setSubmitting(false)
        }
    }

    return (
        <div>
            <div className={s.header}>
                <div className={classNames(s.close, s.close_fake)}/>
                <div className={s.icon}>
                    <Icon/>
                </div>
                <div className={s.close} onClick={onClose}>
                    <Close/>
                </div>
            </div>

            <div className={s.header_title_box}>
                <h3 className={s.title}>Хотите работать в ITEMBUY?</h3>
                <p className={s.sub_title}>Скоро нам потребуется усиление <br/> Выберите вакансию из списка</p>
            </div>

            <Formik
                enableReinitialize={true}
                initialValues={{
                    vacancy: '',
                    file_data: {
                        filename: null,
                        file_value: null,
                    },
                    contact_person: '',
                    phone: '',
                    email: '',
                }}
                validate={values => {
                    const errors = {};

                    const detectFormat = values?.file_data?.filename?.split('.')

                    if (!values.email) {
                        errors.email = 'Обязательное поле';
                    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)) {
                        errors.email = 'Неверный email адресс';
                    }
                    if (!values.phone) {
                        errors.phone = 'Обязательное поле';
                    } else if (values.phone.length !== 18) {
                        errors.phone = 'Неверный номер телефона';
                    }
                    if (!values.vacancy) {
                        errors.vacancy = 'Обязательное поле';
                    }
                    if (!values.contact_person) {
                        errors.contact_person = 'Обязательное поле';
                    }
                    if (!values.file_data.filename || !values.file_data.file_value) {
                        errors.file_data = 'Обязательное поле';
                    } else if (!['pdf', 'docx', 'pptx'].includes(detectFormat?.at(-1)?.toLowerCase())) {
                        errors.file_data = 'Неверный формат файла. Допустимы .pdf, .docx, .pptx';
                    }
                    return errors;
                }}
                onSubmit={(values, {
                    setSubmitting,
                }) => handleSend(values, setSubmitting)}>
                {({values, handleChange, touched, handleSubmit, errors, isSubmitting, setFieldValue}) => {
                    console.log(values)
                    return <form onSubmit={handleSubmit} className={s.action}>
                        <CustomSelect placeholder={'Выберите вакансию'}
                                      name={'vacancy'}
                                      errorMessage={errors.vacancy && touched.vacancy && errors.vacancy}
                                      options={options} value={values.vacancy}
                                      onChange={(e) => setFieldValue('vacancy', e)}/>

                        <Input value={values.contact_person}
                               name={'contact_person'}
                               errorMessage={errors.contact_person && touched.contact_person && errors.contact_person}
                               placeholder={'ФИО'}
                               onChange={handleChange}/>

                        <Input value={values.email}
                               name={'email'}
                               errorMessage={errors.email && touched.email && errors.email}
                               placeholder={'Email'}
                               onChange={handleChange}/>

                        <InputMaskNumber value={values.number} placeholder={'+7 000 000-00-00'}
                                         mask="+7 (999) 999-99-99"
                                         errorMessage={errors.phone && touched.phone && errors.phone}
                                         name={'phone'}
                                         onChange={handleChange}/>

                        <LoadBox name={'file_data'}
                                 errorMessage={errors.file_data && touched.file_data && errors.file_data}
                                 onChange={(e) => setFieldValue('file_data', e)} values={values.file_data}/>
                        <div className={s.btn}>
                            <Button disabled={isSubmitting} type={'submit'}
                                    style={{marginTop: '24px'}}>Отправить</Button>
                        </div>
                    </form>
                }}
            </Formik>

            <FooterModal/>
        </div>
    );
};

export default ModalWorkUs;
