import React from 'react';
import {NavLink, useParams} from "react-router-dom";
import {mock} from "../../../main/blogs/mock";
import s from './styles.module.css'

const AlsoBlogs = () => {
    const {id} = useParams()
    const filtered_data = mock.filter((f) => +f.id !== +id)

    return (
        <div className={s.other}>
            <h3 className={s.title_right}>Читайте также</h3>

            <div className={s.main_content_right_container}>
                {filtered_data.slice(0, 3).map((el, i) => <NavLink to={`/blog/${el.id}`} key={i}>
                    <div className={s.item}>
                        <div className={s.item_img} style={{
                            backgroundImage: `url(${el.img})`
                        }}/>
                        <div className={s.item_description}>
                            <h3 className={s.item_title}>{el.title}</h3>
                            <p className={s.item_date}>{el.date}</p>
                        </div>
                    </div>
                </NavLink>)}

                <NavLink to={'/'} state={{anchor:'blogs'}}>
                    <div className={s.btn}>
                        Все статьи
                    </div>
                </NavLink>
            </div>
        </div>
    );
};

export default AlsoBlogs;
