import React from 'react';
import s from './styles.module.css'
import Wrapper from "../../components/wrapper";
import Title from "../../components/title";
import pdf from '../../assets/pdf/Реквизиты ООО АЙТЕМБАЙ.pdf'

const mock = [
    {title: 'Полное наименование', sub_title: 'ООО «АЙТЕМБАЙ»', id: 1},
    {title: 'ИНН', sub_title: '7751263260', id: 7},
    {title: 'КПП', sub_title: '775101001', id: 8},
    {title: 'ОГРН', sub_title: '1237700538921', id: 9},
    {title: 'Генеральный директор', sub_title: 'Хвостанцева Олеся Владимировна', id: 2},
    {title: 'Наименование банка', sub_title: 'ПАО СБЕРБАНК', id: 3},
    {title: 'Корреспондентский счет', sub_title: '30101810400000000225', id: 4},
    {title: 'БИК', sub_title: '044525225', id: 5},
    {title: 'Расчетный счет', sub_title: '40702810740000097927', id: 6},

    {
        title: 'Юридический адрес',
        sub_title: '108811, город Москва, г. Московский, ул.Бианки, д. 1, кв. 196',
        id: 10
    },
]

const Requisites = () => {

    return (
        <div className={s.requisites}>
            <Wrapper>
                <Title title={'Реквизиты организации'}/>
                <div className={s.table}>
                    {mock.map((el, i) => {
                        return <div key={el.id} style={{backgroundColor: i % 2 ? '#fff' : '#F4F5F6'}} className={s.row}>
                            <p className={s.table_title}>{el.title}</p>
                            <p className={s.table_sub_title}>{el.sub_title}</p>
                        </div>
                    })}
                </div>
                {/*<PDFDownloadLink document={<MyDocument data={mock}/>}>*/}
                <a
                    href={pdf}
                    download={`Реквизиты ООО АЙТЕМБАЙ.pdf`}
                    target="_blank"
                    rel="noreferrer"
                >
                    <div className={s.btn}>Скачать PDF</div>
                </a>
                {/*</PDFDownloadLink>*/}

            </Wrapper>
        </div>
    );
};

export default Requisites;
