import React, {useEffect, useState} from 'react';
import {ReactComponent as Pdf} from "../../assets/pdf.svg";
import s from './styles.module.css'
import DownloadIcon from '@mui/icons-material/Download';
import {Tooltip} from "@mui/material";

const Item = ({file, title}) => {
    const [sizeFile, setSizeFile] = useState('')

    useEffect(() => {
        fetch(file)
            .then(response => response.blob())
            .then(blob => {
                function formatBytes(bytes, decimals = 2) {
                    if (!+bytes) return '0 байт'

                    const k = 1024
                    const dm = decimals < 0 ? 0 : decimals
                    const sizes = ['байт', 'КБ', 'МБ', 'ГБ', 'ТБ']

                    const i = Math.floor(Math.log(bytes) / Math.log(k))

                    return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`
                }

                setSizeFile(formatBytes(blob.size))
            })
            .catch(error => console.error(error));
    }, [])
    return (
        <a
            href={file}
            // download={`${title}.pdf`}
            target="_blank"
            rel="noreferrer"
        >

            <div className={s.item}>
                <div className={s.item_left}>
                    <Pdf/>

                    <div className={s.info}>

                        <p className={s.title}>{title}</p>
                        <p className={s.size}>{sizeFile}</p>
                    </div>
                </div>
                <div className={s.download} onClick={(e) => e.stopPropagation()}>
                    <Tooltip title={'Скачать'}>
                        <a
                            href={file}
                            download={`${title}.pdf`}
                            target="_blank"
                            rel="noreferrer"
                        >
                            <DownloadIcon sx={{color: '#91969F'}}/>
                        </a>
                    </Tooltip>
                </div>
            </div>
        </a>
    );
};

export default Item;
