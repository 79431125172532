import React, {useEffect, useState} from 'react';
import s from './styles.module.css'
import {useLocation, useParams} from "react-router-dom";
import {mock} from "./mock";
import {ReactComponent as View} from "../../assets/view.svg";
import BlogItemTop from "./blog_item_top";
import BlogItemContent from "./blog_item_content";
import Wrapper from "../../components/wrapper";
import AlsoBlogs from "./blog_item_top/also_blogs";
import axios from "axios";

const BlogItem = () => {
    const location = useLocation()
    const {id} = useParams()
    const data = mock[id]

    const [viewCounter, setViewCounter] = useState(null)
    const [likeCounter, setLikeCounter] = useState(null)

    const handleGetViewCount = async () => {
        try {
            const res = await axios.get(`https://itembuy.tech/public/api/get-article-view?blog_id=${id}`)
            setViewCounter(res.data.count_views)
        } catch (e) {
            console.log(e)
        }
    }

    const handleGetViewLikes = async () => {
        try {
            const res = await axios.get(`https://itembuy.tech/public/api/get-like-in-article?blog_id=${id}`)
            setLikeCounter({
                count_like: res.data.count_likes,
                has_like: res.data.has_like
            })
        } catch (e) {
            console.log(e)
        }
    }

    const handleSetViewLike = async (has_like) => {
        try {
            const res1 = await axios.get(`https://itembuy.tech/public/api/set-like-in-article?blog_id=${id}&has_like=${has_like}`)
            const res = await axios.get(`https://itembuy.tech/public/api/get-like-in-article?blog_id=${id}`)

            setLikeCounter({
                count_like: res.data.count_likes,
                has_like: res.data.has_like
            })
        } catch (e) {
            console.log(e)
        }
    }

    useEffect(() => {
        handleGetViewCount()
        handleGetViewLikes()
    }, [id])

    useEffect(() => {
        window.scrollTo({top: 0, behavior: 'smooth'});
    }, [location.pathname])

    return (
        <Wrapper>
            <div className={s.blogs}>

                <div className={s.header}>
                    <div className={s.info_box}>
                        <p className={s.date}>{data.date}</p>

                        <p className={s.view_box}>
                            <div className={s.view_icon}><View/></div>
                            <p className={s.view_count}>{viewCounter !== null ? viewCounter : '-'}</p>
                        </p>
                    </div>
                    <h1 className={s.title}>{data.main_title}</h1>
                </div>

                <BlogItemTop img={data.img}/>

                <BlogItemContent data={data} likeCounter={likeCounter} handleSetViewLike={handleSetViewLike}/>

                <div className={s.also_blogs}>
                    <AlsoBlogs/>
                </div>
            </div>
        </Wrapper>
    );
};

export default BlogItem;
