import React from 'react';
import {Route, Routes} from "react-router-dom";
import Main from "../page/main";
import Requisites from "../page/requisites";
import DocumentsRoots from "../page/document_roots";
import Contacts from "../page/contacts";
import Blog from "../page/blog";

const Router = () => {
    return (
        <Routes>
            <Route path={'/'} element={<Main/>}/>
            <Route path={'requisites'} element={<Requisites/>}/>
            <Route path={'documents-roots'} element={<DocumentsRoots/>}/>
            <Route path={'contact'} element={<Contacts/>}/>
            <Route path={'blog/:id'} element={<Blog/>}/>
            <Route path={'*'} element={<Main/>}/>
        </Routes>
    );
};

export default Router;
