import React from 'react';
import s from './styles.module.css'
import {ReactComponent as Plus} from "../../assets/plus.svg";
import {ReactComponent as Remove} from "../../assets/delete.svg";
import classNames from "classnames";

const LoadBox = ({onChange, errorsFormat = false, values, name, errorMessage}) => {
    const handleFileUpload = (event, key) => {
        if (event.target.files[0]) {
            const file = event.target.files[0];
            const detectFormat = file.name.split('.')
            //
            // if (['png', 'jpg'].includes(detectFormat?.at(-1)?.toLowerCase()) && file.size <= 10485760) {
            //     setErrorsFormat(false)

            const reader = new FileReader();
            reader.onloadend = () => {
                onChange({file_value: reader.result, filename: file.name === '' ? 'file' : file.name});
            };

            reader.readAsDataURL(file)
            // } else if (!['png', 'jpg'].includes(detectFormat?.at(-1)?.toLowerCase())) {
            //     setErrorsFormat('format')
            // } else if (file.size > 10485760) {
            //     setErrorsFormat('size')
            // }

        }
    };
    return (
        <div>
            <div className={classNames(s.item, errorMessage && s.error_dash)}>
                <div className={classNames(s.item_content, errorMessage && s.error_bg)}>
                    {!values.file_value ? <>
                            <input name={name} onChange={handleFileUpload}
                                   accept=".pdf, .docx, .pptx"
                                   type="file" className={s.file}/>
                            <p className={s.target_title}>Прикрепите резюме</p>

                            <div className={s.target_box}>
                                <div className={s.target_box_icon}>
                                    <Plus/>
                                </div>
                                <p className={s.target_box_title}>Выбрать</p>
                            </div>
                        </>
                        :
                        <div className={s.media_upload_box}>
                            <p className={s.target_title}>{values.filename}</p>
                            <div className={s.remove} onClick={() => onChange({
                                filename: null,
                                file_value: null,
                            })}>
                                <Remove/>
                            </div>
                        </div>
                    }
                </div>

            </div>
            <span className={s.errorMessage}>{errorMessage}</span>
        </div>
    );
};

export default LoadBox;
