import React from 'react';
import s from './styles.module.css'
import file2 from "../../../assets/pdf/Согласие_на_обработку_персональных_данных_ООО_«АЙТЕМБАЙ».pdf";

const FooterModal = () => {
    return (
        <div className={s.title}>
            Нажимая на кнопку вы даете согласие на <a
            href={file2}
            target="_blank"
            rel="noreferrer"
        ><b>обработку персональных данных</b></a>.
        </div>
    );
};

export default FooterModal;
