import React from 'react';
import s from './styles.module.css'
import {ReactComponent as Close} from "../../../assets/close.svg";
import Input from "../../input";
import TextArea from "../../textarea";
import Button from "../../button";
import FooterModal from "../../custom_modal/footer_modal";
import {Formik} from "formik";
import axios from "axios";
import {toast} from "react-toastify";

const ModalAskQuestion = ({onClose}) => {

    const handleSend = async (values, setSubmitting) => {
        setSubmitting(true)
        try {
            const res = await axios.post(`https://itembuy.tech/public/api/send-question`, {
                subject: values.subject,
                description: values.description,
                email: values.email
            })
            setSubmitting(false)
            onClose()
            toast.success('Ваша заявка отправлена!', {
                position: "top-center",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "colored",
            });

        } catch (e) {
            console.log(e)
            setSubmitting(false)
        }
    }

    return (
        <div>
            <div className={s.header}>
                <h3 className={s.title}>Задать вопрос</h3>
                <div className={s.close} onClick={onClose}>
                    <Close/>
                </div>
            </div>

            <Formik
                enableReinitialize={true}
                initialValues={{
                    subject: '',
                    description: '',
                    email: '',
                }}
                validate={values => {
                    const errors = {};
                    if (!values.email) {
                        errors.email = 'Обязательное поле';
                    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)) {
                        errors.email = 'Неверный email адресс';
                    }
                    if (!values.description) {
                        errors.description = 'Обязательное поле';
                    }
                    if (!values.subject) {
                        errors.subject = 'Обязательное поле';
                    }
                    return errors;
                }}
                onSubmit={(values, {
                    setSubmitting,
                }) => handleSend(values, setSubmitting)}>
                {({values, handleChange, touched, handleSubmit, errors, isSubmitting, handleBlur}) => {

                    return <form onSubmit={handleSubmit} className={s.action}>
                        <Input value={values.subject} errorMessage={errors.subject && touched.subject && errors.subject}
                               name={'subject'} placeholder={'Тема вопроса'}
                               onChange={handleChange}/>
                        <TextArea value={values.description}
                                  errorMessage={errors.description && touched.description && errors.description}
                                  name={'description'} placeholder={'Задайте свой вопрос'}
                                  onChange={handleChange}/>
                        <Input value={values.email} errorMessage={errors.email && touched.email && errors.email}
                               name={'email'} placeholder={'Введите почту'}
                               onChange={handleChange}/>

                        <div className={s.btn}>
                            <Button disabled={isSubmitting} type={'submit'}
                                    style={{marginTop: '24px'}}>Отправить</Button>
                        </div>
                    </form>
                }}
            </Formik>

            <FooterModal/>
        </div>
    );
};

export default ModalAskQuestion;
