import React, {useEffect} from 'react';
import classNames from "classnames";
import s from "../styles.module.css";

const Character = ({
                       data,
                       activeCharacter,
                       setActiveCharacter,
                       pageHeight = 100,
                       refs,
                   }) => {

    const observerMargin = Math.floor(pageHeight / 2);

    useEffect(() => {
        const observerConfig = {
            rootMargin: `-100px 0px -${observerMargin + (pageHeight / 2) - 100}px 0px`,
        };
        const handleIntersection = function (entries) {
            entries.forEach((entry) => {
                if (+entry.target.id !== +activeCharacter && entry.isIntersecting) {
                    setActiveCharacter(+entry.target.id);
                }
            });
        };
        const observer = new IntersectionObserver(
            handleIntersection,
            observerConfig
        );
        observer.observe(refs[data?.title].current);
        return () => observer.disconnect(); // Clenaup the observer if component unmount.
    }, [
        activeCharacter,
        setActiveCharacter,
        observerMargin,
        refs,
        data,
        pageHeight,
    ]);

    return (
        <div
            ref={refs[data?.title]}
            id={data.id}
        >
            <div className={classNames(s.chapter_item)} id={data.id}>
                <h1 className={s.chapter_title}>{data.title}</h1>
                <p className={s.chapter_sub_title}>{data.sub_title}</p>
            </div>
        </div>
    );
}

export default Character
