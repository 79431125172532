import React from 'react';
import s from './styles.module.css'
import {NavLink} from "react-router-dom";

const BlogItemLarge = ({date, title, img,id}) => {
    return (
        <NavLink to={`blog/${id}`} className={s.link}>
        <div className={s.blog}>
            <img className={s.img} src={img} alt="title"/>
            <div className={s.description_box}>
                <p className={s.date}>{date}</p>
                <p className={s.title}>{title}</p>
            </div>
        </div>
        </NavLink>
    );
};

export default BlogItemLarge;
