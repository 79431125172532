import React, {useState} from 'react';
import s from './styles.module.css'
import Wrapper from "../wrapper";
import {NavLink, useNavigate} from "react-router-dom";
import Logo from "../../assets/logo";
import LogoText from "../../assets/logo_text";
import {ReactComponent as Vk} from "../../assets/vk.svg";
import {ReactComponent as Tg} from "../../assets/tg.svg";
import classNames from "classnames";
import CustomModal from "../custom_modal";
import ModalWorkUs from "./modal_work_us";
import ModalStaySupplier from "./modal_stay_supplier";
import ModalAskQuestion from "./modal_ask_qestion";
import {ToastContainer} from "react-toastify";

const Footer = () => {
    const navigate = useNavigate()

    const [openWorkUs, setOpenWorkUs] = useState(false)
    const [openSupplier, setOpenSupplier] = useState(false)
    const [openAskQuestion, setOpenAskQuestion] = useState(false)

    return (
        <div className={s.footer}>
            <ToastContainer
                // icon={false}
                // toastStyle={{ backgroundColor: "#EAF8EC" ,color:'#29B942'}}
                position="top-center"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="colored"
            />
            <CustomModal open={openWorkUs} onClose={() => setOpenWorkUs(false)}>
                <ModalWorkUs onClose={() => setOpenWorkUs(false)}/>
            </CustomModal>

            <CustomModal open={openSupplier} onClose={() => setOpenSupplier(false)}>
                <ModalStaySupplier onClose={() => setOpenSupplier(false)}/>
            </CustomModal>

            <CustomModal open={openAskQuestion} onClose={() => setOpenAskQuestion(false)}>
                <ModalAskQuestion onClose={() => setOpenAskQuestion(false)}/>
            </CustomModal>

            <Wrapper>
                <div className={s.footer_content}>
                    <div className={s.footer_top}>
                        <div className={s.logo_box}>
                            <div className={s.logo_box_wrapper}>
                                <Logo className={s.logo} onClick={() => navigate('/')}/>
                                <LogoText color={'#fff'} className={s.logo_text} style={{marginLeft: '14px'}}
                                          onClick={() => navigate('/')}/>
                            </div>
                            <p className={s.mobile_title}>Онлайн-аукцион</p>
                        </div>

                        <div className={classNames(s.box_list, s.box_list_1)}>
                            <h3 className={s.title_ul}>Компания</h3>
                            <div className={s.list}>
                                <NavLink to={'/blog/1'}>О нас</NavLink>
                                <NavLink to={'/requisites'}>Реквизиты</NavLink>
                                <NavLink to={'/documents-roots'}>Документы и правила</NavLink>
                                <NavLink to={'/contact'}>Контакты</NavLink>
                            </div>
                        </div>

                        <div className={classNames(s.box_list, s.box_list_2)}>
                            <h3 className={s.title_ul}>Партнерам</h3>
                            <div className={s.list}>
                                <p onClick={() => setOpenSupplier(true)}>Стать поставщиком</p>
                                <p onClick={() => setOpenWorkUs(true)}>Работать с нами</p>
                            </div>
                        </div>

                        <div className={s.contact}>
                            <a href="tel:8(800)2509133">
                                <p className={s.number}>8 (800) 250-91-33</p>
                            </a>
                            <a href={`mailto:info@itembuy.ru`}>
                                <p className={s.email}>info@itembuy.ru</p>
                            </a>
                        </div>
                    </div>

                    <div className={s.action_footer}>
                        <div className={s.social}>
                            <a href="https://vk.ru/itembuy" target={'_blank'}>
                                <div className={s.social_item}>
                                    <Vk/>
                                </div>
                            </a>

                            <a href="https://t.me/itembuy" target={'_blank'}>
                                <div className={classNames(s.social_item, s.tg)}>
                                    <Tg/>
                                </div>
                            </a>
                        </div>
                        <div/>
                        <div/>
                        <div className={s.btn} onClick={() => setOpenAskQuestion(true)}>Написать в поддержку</div>
                    </div>

                    <div className={s.footer_bottom}>
                        <p>© 2023 ITEMBUY — Онлайн-аукцион электроники, бытовой техники, модных аксессуаров,
                            автомобилей, недвижимости.
                            <br/>Все права защищены.</p>
                    </div>
                </div>
            </Wrapper>
        </div>
    );
};

export default Footer;
