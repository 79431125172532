import React from 'react';
import s from './styles.module.css'
import Wrapper from "../../../components/wrapper";
import {mock} from "./mock";
import BlogItemLarge from "./blog_item/blog_item_large";
import BlogItemSmall from "./blog_item/blog_item_small";
import {useMediaQuery} from "@mui/material";

const Blogs = () => {
    const maxWidth = useMediaQuery('(max-width:1024px)');

    const large_blogs = mock.slice(0, 2)
    const small_blogs = mock.slice(2, 5)

    return (
        <div className={s.blogs}>
            <Wrapper className={s.wrapper}>
                <div className={s.blogs_content} id={'blogs'}>
                    <h1 className={s.title}>Свежие новости</h1>

                    <div className={s.blogs_items}>
                        <div className={s.blogs_items_big}>
                            {large_blogs.map((el, i) => <BlogItemLarge key={i} {...el}/>)}
                        </div>
                        <div className={s.blogs_items_small}>
                            {(maxWidth ? mock : small_blogs).map((el, i) => <BlogItemSmall key={i} {...el}/>)}
                        </div>
                    </div>
                </div>
            </Wrapper>
        </div>
    );
};

export default Blogs;
