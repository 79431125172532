import img1 from '../../assets/blog_img/img1_b.png'
import img2 from '../../assets/blog_img/img2_b.png'
import img3 from '../../assets/blog_img/img3_b.png'
import img4 from '../../assets/blog_img/img4_b.png'
import img5 from '../../assets/blog_img/img5_b.png'

export const mock = {
    1: {
        main_title: 'ITEMBUY — идея создания и миссия сервиса онлайн-аукционов',
        date: '09 Авг 2023',
        img: img1,
        chapter: [
            {
                title: 'Идея создания платформы',
                sub_title: <>Идея создания ITEMBUY появилась в 2015 году в ходе увлекательной беседы о том, что
                    привычные модели реализации товаров исчерпали свой потенциал. Что традиционные способы продажи
                    теряют актуальность в современном мире.
                    <br/>
                    <br/>
                    В эпоху жёсткой рыночной конкуренции, для сохранения заработка, продавцам приходится прибегать к
                    реализации различных, изощрённых, а иногда даже противозаконных схем. Покупатели, в свою очередь,
                    вынуждены мириться с рыночными условиями и завышенными ценами на желаемые товары.
                    <br/>
                    <br/>
                    Вся совокупность факторов, в конечном итоге, приводит к уменьшению спроса, что является следствием
                    снижения покупательской способности. Возникают социальные проблемы, возрастает закредитованность
                    населения.
                    <br/>
                    <br/>
                    Мы задали себе вопрос: «Почему в эпоху быстрой информации и безграничных возможностей, все должны
                    подчиняться ограничениям и нарушать этические принципы, когда можно создать среду, где каждый
                    участник сделки будет выигрывать?»
                    <br/>
                    <br/>
                    Это и был миг рождения ITEMBUY - платформы, которая объединяет продавцов и покупателей во
                    взаимовыгодной среде, основанной на прозрачности, справедливости и инновационных подходах.</>,
                id: 1
            },
            {
                title: 'Миссия',
                sub_title: <>Наша мечта — это справедливый и открытый рынок для всех. Вместе мы можем сделать его
                    реальным.
                    <br/>
                    <br/>
                    Наша миссия — создать инновационную платформу, которая поднимает взаимодействие продавцов и
                    покупателей на новый уровень, где каждый участник получает максимальную выгоду.
                    <br/>
                    <br/>
                    Мы верим в мир, где торговля становится свободной, честной и доступной для каждого — именно к этому
                    мы
                    стремимся каждый день.</>,
                id: 2
            },
        ],
        id: 1
    },
    2: {
        main_title: 'Как мы переосмыслили традиционные аукционы в технологичное IT-решение',
        date: '08 Авг 2023',
        img: img2,
        chapter: [
            {
                title: 'Механика проекта',
                sub_title: <>В сердце ITEMBUY бьется механизм аукциона - современное переосмысление старинной формы
                    торговли. Мы
                    внедрили аукционную модель продажи товаров, которая наполняет торговлю динамикой, при этом
                    обеспечивая свободную и открытую конкуренцию между покупателями.
                    <br/>
                    <br/>
                    Наша аукционная площадка отличается от традиционных. У нас нет минимальной выкупной стоимости,
                    которая
                    обычно устанавливается на аукционах. У нас каждый товар, независимо от его исходной стоимости, может
                    быть
                    продан по цене, которую установят сами участники аукциона. Это значит, что итоговая цена товара
                    формируется под действием самых чистых законов рынка - предложения и спроса.
                    <br/>
                    <br/>
                    Однако мы не забыли о продавцах. Для них мы разработали уникальную систему компенсационных фондов.
                    Эти
                    фонды пополняются за счет комиссий, которые мы взимаем с пользователей при участии в аукционах.
                    <br/>
                    <br/>
                    Мы
                    используем сложные алгоритмы и тщательный анализ данных для определения оптимального размера
                    комиссии для каждого товара, учитывая спрос, рыночную активность и ликвидность.
                    <br/>
                    <br/>
                    Скомпенсировав разницу между начальной стоимостью товара и его итоговой ценой на аукционе, мы
                    обеспечиваем необходимую выгоду продавцам. Так, наша платформа не просто предлагает возможность
                    покупки и продажи, но и создает сбалансированную коммерческую среду, где интересы всех участников
                    учтены и защищены.</>,
                id: 1
            },
            {
                title: 'Собственные IT-решения',
                sub_title: <>В основе ITEMBUY заложена сложная IT-инфраструктура, которая полностью разработана
                    программистами компании, и не имеющая аналогов на российском и зарубежном рынках. Собственное
                    программное обеспечение используется во всех сегментах бизнеса: внутренних алгоритмах платформы,
                    аукционной системе, аналитики, системе управления партнерской сетью и т.д.
                    <br/>
                    <br/>
                    Компания развиватет AI, который применяется во внутренних сервисах, контакт-центре, технической
                    поддержке, маркетинге. Уникальными являются и финтех-решения ITEMBUY, покупателям и партнерам
                    предлагается широкий спектр платежных инструментов и финансовых услуг.
                    <br/>
                    <br/>
                    Собственная разработка программного обеспечения позволяет компании гибко и нестандартно реагировать
                    на любые внешние и внутренние факторы, быстро адаптируясь под новые задачи и продолжать развивать
                    платформу вне зависимости от внешних обстоятельств.</>,
                id: 2
            },
        ],
        id: 2
    },
    3: {
        main_title: 'Основные принципы и цели сервиса',
        date: '07 Авг 2023',
        img: img3,
        chapter: [
            {
                title: 'Основные принципы и цели',
                sub_title: <>В ITEMBUY, мы строим нашу деятельность на пяти ключевых принципах, которые определяют наш
                    путь к преобразованию
                    торговли и созданию справедливой, прозрачной и эффективной системы.</>,
                id: 1
            },
            {
                title: 'Прозрачность',
                sub_title: <>Мы не просто говорим о прозрачности, мы наглядно демонстрируем это на каждом этапе работы
                    нашей
                    платформы. Информация о товарах, аукционах и условиях продажи доступна для всех участников. Более
                    того, мы
                    представляем отчётность о нашей деятельности в открытом доступе, чтобы каждый мог увидеть наши
                    успехи и понять,
                    насколько нам удалось приблизиться к нашим целям.</>,
                id: 2
            },
            {
                title: 'Справедливость',
                sub_title: <>В ITEMBUY, каждая сделка — это воплощение справедливости. Наша аукционная модель позволяет
                    каждому
                    участнику принять активное участие в формировании итоговой цены товара. Никаких искусственных
                    ограничений: только
                    законы спроса и предложения.</>,
                id: 3
            },
            {
                title: 'Инновации',
                sub_title: <>Без прогресса невозможно двигаться вперёд. Поэтому ITEMBUY использует последние технологии
                    и
                    инновационные методы для анализа данных и определения оптимальных размеров комиссий. Наш подход
                    позволяет создать
                    сбалансированную коммерческую среду, где каждый участник получает максимальную выгоду.</>,
                id: 4
            },
            {
                title: 'Взаимовыгода',
                sub_title: <>В нашем мире нет проигравших. Мы стремимся создать платформу, где каждый участник
                    выигрывает, будь то
                    продавец или покупатель. Наша система компенсационных фондов помогает обеспечить взаимовыгодные
                    условия для всех.</>,
                id: 5
            },
            {
                title: 'Эффективность',
                sub_title: <>В ITEMBUY, мы ценим не только результат, но и путь к нему. Применение прогрессивной
                    бизнес-модели и
                    оптимальное использование ресурсов, помогают управлять рисками и обеспечивать стабильность в работе
                    платформы.</>,
                id: 6
            },
        ],
        id: 3
    },
    4: {
        main_title: 'Выгода до 60% от рекомендованной стоимости товаров и другие преимущества сервиса',
        date: '06 Авг 2023',
        img: img4,
        chapter: [
            {
                title: 'Самоопределение цен',
                sub_title: <>В ITEMBUY, каждая сделка — это воплощение справедливости. Наша аукционная модель позволяет
                    каждому
                    участнику принять активное участие в формировании итоговой цены товара. Никаких искусственных
                    ограничений: только
                    законы спроса и предложения.</>,
                id: 1
            },
            {
                title: 'Существенная экономия',
                sub_title: <>ITEMBUY позволяет приобретать товары ниже их рекомендованной стоимости до 60%,
                    что делает покупки на платформе выгодными.</>,
                id: 2
            },
            {
                title: 'Гарантированный доход продавцу',
                sub_title: <>Продавец получает гарантированную розничную стоимость товара, минимизируя
                    риски и получая справедливую прибыль.</>,
                id: 3
            },
            {
                title: 'Инновационная технология',
                sub_title: <>Наша уникальная технология позволяет покрывать разницу стоимости товара за счёт
                    компенсационных фондов, формируемых за счёт уплаченных комиссий.</>,
                id: 4
            },
            {
                title: 'Геймификация покупок',
                sub_title: <>Процесс покупки на ITEMBUY является не только выгодным, но ещё увлекательным и
                    интересным.</>,
                id: 5
            },
            {
                title: 'Прозрачность',
                sub_title: <>Наша платформа обеспечивает полную прозрачность всех процессов, что позволяет пользователям
                    легко
                    принимать обоснованные решения.</>,
                id: 6
            },
        ],
        id: 4
    },
    5: {
        main_title: 'Отвечаем на частые вопросы: что такое онлайн-аукционы, зачем в них участвовать и в чем выгода?',
        date: '06 Авг 2023',
        img: img5,
        chapter: [
            {
                title: 'Что такое интернет-аукцион?',
                sub_title: <>Интернет-аукцион – это платформа, где продажа товаров происходит через систему торгов, в
                    которой покупатели
                    соревнуются за право приобретения товара по лучшей цене. Основная особенность интернет-аукционов —
                    это динамичность и прямая зависимость стоимости товара от активности и интереса к нему у участников
                    торгов.</>,
                id: 1
            },
            {
                title: 'Почему стоит выбрать именно эту платформу?',
                sub_title: <>ITEMBUY стоит выбрать за его уникальную, демократичную и справедливую модель торгов. Здесь
                    нет минимальной
                    выкупной стоимости. Это означает, что итоговая цена товара определяется полностью участниками
                    аукциона.</>,
                id: 2
            },
            {
                title: 'Меня не обманут?',
                sub_title: <>ITEMBUY работает в полном соответствии с законами РФ, регулирующими аукционную деятельность
                    и защиту прав
                    потребителей. Мы серьезно относимся к своим обязательствам и обеспечиваем честность и справедливость
                    всех
                    процессов. Вся информация о товарах, условиях продажи и аукционах открыта для каждого участника.
                    Благодаря
                    прозрачной системе контроля и строгому соблюдению правил, мы обеспечиваем безопасность и защиту
                    интересов
                    всех участников нашей платформы.</>,
                id: 3
            },
            {
                title: 'Что влияет на стоимость?',
                sub_title: <>В нашей системе, на конечную стоимость товара влияют исключительно ставки участников
                    аукциона. На размер ставок,
                    в свою очередь, влияют такие факторы, как спрос, активность на рынке и ликвидность товара. В
                    ITEMBUY, мы используем
                    высокотехнологичные алгоритмы для анализа этих данных, чтобы установить оптимальную начальную цену и
                    размер комиссии.</>,
                id: 4
            },
            {
                title: 'Какую выгоду получу?',
                sub_title: <>ITEMBUY предлагает уникальные условия для каждого участника. Покупатели- получают
                    возможность приобрести товары
                    по цене, которую они сами определяют. Продавцы- получают желаемый результат от сделки.</>,
                id: 5
            },
            {
                title: 'Как формируется стоимость товара?',
                sub_title: <>Стоимость товара формируется в результате проведения аукциона. Покупатели делают ставки, а
                    итоговая цена товара
                    определяется последней ставкой к моменту окончания аукциона.</>,
                id: 6
            },
            {
                title: 'На чём зарабатывает сервис?',
                sub_title: <>Платформа ITEMBUY зарабатывает на части комиссий, которые взимаются с участников аукционов
                    и распределяются после
                    формирования компенсационных фондов. Это позволяет обеспечивать стабильную коммерческую деятельность
                    и
                    взаимовыгодные условия для всех участников.</>,
                id: 7
            },
        ],
        id: 5
    },
}
