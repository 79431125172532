import React, {createRef, useEffect, useRef, useState} from 'react';
import s from './styles.module.css'
import LikeSocial from "./like_social";
import classNames from "classnames";
import {InView} from "react-intersection-observer";
import Character from "./item_blog";

const BlogItemContent = ({data,likeCounter,handleSetViewLike}) => {
    // const [chapter, setChapter] = useState(data.chapter[0].id)
    const [activeCharacter, setActiveCharacter] = useState(data.chapter[0].id);
    const [pageHeight, setPageHeight] = useState();

    useEffect(() => {
        setPageHeight(window.innerHeight);
        window.addEventListener('resize', (e) => {
            setTimeout(() => {
                setPageHeight(window.innerHeight);
            }, 300);
        });
    }, []);

    const handleClick = (anchorTarget) => {
        anchorTarget?.scrollIntoView({behavior: 'smooth', block: 'start', inline: 'center'});
    };
    const refs = data.chapter.reduce((refsObj, character) => {
        refsObj[character.title] = createRef();
        return refsObj;
    }, {});
    return (
        <div className={s.content}>

            <div className={classNames(s.left)}>

                {data.chapter.map((el, i) => <Character
                    pageHeight={pageHeight}
                    setActiveCharacter={setActiveCharacter}
                    activeCharacter={activeCharacter}
                    refs={refs}
                    data={el}
                    key={el.id}/>
                )}
            </div>

            <div className={s.right}>
                <LikeSocial likeCounter={likeCounter} handleSetViewLike={handleSetViewLike}/>

                <div className={s.anchor_contnt}>
                    <h1 className={s.anchor_title_box}>Содержание</h1>
                    <div className={s.anchor_item_content}>
                        <div className={s.line}/>
                        {data.chapter.map((el, i) => <div key={i} className={s.anchor_item}
                                                          style={{
                                                              marginTop: i === 0 && 0
                                                          }}
                                                          onClick={(e) => {
                                                              e.preventDefault()
                                                              handleClick(document.getElementById(el.id))
                                                              // setChapter(el.id)
                                                          }}>
                            <div className={classNames(s.circle, +activeCharacter === el.id && s.circle_select)}/>
                            <span
                                className={classNames(s.anchor_title, +activeCharacter === el.id && s.anchor_title_select)}>{el.title}</span>
                        </div>)}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default BlogItemContent;


