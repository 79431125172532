import React from 'react';
import s from './styles.module.css'
import {NavLink} from "react-router-dom";

const BlogItemSmall = ({date, title, img, id}) => {
    return (
        <NavLink to={`blog/${id}`} className={s.link}>
            <div className={s.blog}>

                <img src={img} alt={title} className={s.img}/>

                <div className={s.description_box}>
                    <p className={s.title}>{title}</p>
                    <p className={s.date}>{date}</p>
                </div>

            </div>
        </NavLink>
    );
};

export default BlogItemSmall;
