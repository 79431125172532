import React from 'react';
import InputMask from "react-input-mask";
import s from './styles.module.css'

const InputMaskNumber = ({value, mask, placeholder, onChange, name, errorMessage}) => {
    return (
        <div className={s.input_mask}>
            <InputMask maskChar={null} type={'tel'} name={name} mask={mask} placeholder={placeholder}
                       value={value}
                       onChange={onChange}
            />
            <span className={s.errorMessage}>{errorMessage}</span>
        </div>
    );
};

export default InputMaskNumber;
