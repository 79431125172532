import React, {useState} from 'react';
import s from './styles.module.css'
import Wrapper from "../../components/wrapper";
import Title from "../../components/title";
import classNames from "classnames";
import {ReactComponent as Vk} from "../../assets/vk.svg";
import {ReactComponent as Tg} from "../../assets/tg.svg";
import {ReactComponent as Plane} from "../../assets/plane.svg";
import CustomModal from "../../components/custom_modal";
import ModalAskQuestion from "../../components/footer/modal_ask_qestion";
import {ToastContainer} from "react-toastify";

const mock = [
    {title: 'Бизнесу', sub_title: 'По вопросам и предложениям о сотрудничестве', email: 'bz@itembuy.ru', id: 1},
    {
        title: 'Государству',
        sub_title: 'Для входящей корреспонденции от органов государственной власти',
        email: 'gv@itembuy.ru',
        id: 2
    },
    {title: 'Для прессы', sub_title: 'По всем вопросам, адресованным в пресс-службу', email: 'pr@itembuy.ru', id: 3},
    {
        title: 'Пользователям',
        sub_title: 'По любым вопросам и предложениям по улучшению сервиса',
        email: 'info@itembuy.ru',
        id: 4
    },
]

const Contacts = () => {
    const [openAskQuestion, setOpenAskQuestion] = useState(false)

    return (
        <div className={s.contact}>
            <ToastContainer
                // icon={false}
                // toastStyle={{ backgroundColor: "#EAF8EC" ,color:'#29B942'}}
                position="top-center"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="colored"
            />
            <CustomModal open={openAskQuestion} onClose={() => setOpenAskQuestion(false)}>
                <ModalAskQuestion onClose={() => setOpenAskQuestion(false)}/>
            </CustomModal>

            <Wrapper>
                <Title title={'Контакты'}/>

                <div className={s.content}>
                    <div className={s.content_top}>
                        {mock.map((el) => <div key={el.id} className={s.content_top_item}>
                            <div>
                                <h3 className={s.content_top_item_title}>{el.title}</h3>
                                <p className={s.content_top_item_sub_title}>{el.sub_title}</p>
                            </div>
                            <div>
                                <p className={s.email}>E-mail</p>
                                <a href={`mailto:${el.email}`}><p className={s.email_email}>{el.email}</p></a>
                            </div>
                        </div>)}
                    </div>
                    <div className={s.content_bottom}>
                        <div className={s.social}>
                            <div className={s.plane}>
                                <Plane/>
                            </div>
                            <div>
                                <h3 className={s.content_bottom_title}>Мы в социальных сетях</h3>
                                <p className={s.content_bottom_sub_title}>Обсуждайте, следите за новостями, акциями и
                                    обновлениями</p>
                            </div>
                            <div className={s.social_box}>
                                <a href="https://vk.ru/itemb" target={'_blank'}>
                                    <div className={s.social_icon}><Vk/></div>
                                </a>
                                <a href="https://t.me/itembuy" target={'_blank'}>
                                    <div className={classNames(s.social_icon, s.social_icon_v2)}><Tg/></div>
                                </a>
                            </div>
                        </div>
                        <div className={s.form}>
                            <div>
                                <h3 className={classNames(s.content_bottom_title, s.content_bottom_title_v2)}>Форма
                                    обратной связи</h3>
                                <p className={classNames(s.content_bottom_sub_title, s.content_bottom_sub_title_v2)}>Вы
                                    можете задать вопрос, используя форму обратной связи</p>
                            </div>
                            <div className={s.btn} onClick={() => setOpenAskQuestion(true)}>Задать вопрос</div>
                        </div>
                    </div>
                </div>
            </Wrapper>
        </div>
    );
};

export default Contacts;
