import React, {useState} from 'react';
import s from './styles.module.css'
import {ReactComponent as Telegram} from '../../../../assets/tg.svg'
import {ReactComponent as Like} from '../../../../assets/like.svg'
import {ReactComponent as VK} from '../../../../assets/vk.svg'
import {IconButton} from "@mui/material";

const LikeSocial = ({likeCounter, handleSetViewLike}) => {
    // const [likeCount, setLikeCount] = useState({
    //     isLike: true,
    //     count: 23
    // })


    const share_telegram_link = `https://t.me/share/url?url=${window.location.href}`
    const share_vk_link = `https://vk.com/share.php?url=${window.location.href}`

    return (
        <div className={s.content}>
            <div className={s.like_content}>
                <IconButton aria-label="like" size="small"
                            onClick={() => handleSetViewLike(!Boolean(likeCounter?.has_like))} sx={{
                    height: '56px',
                    width: '56px',
                    color: '#5654D4',
                    background: Boolean(likeCounter?.has_like) ? 'rgb(255, 211, 74)' : '#e1e1e1',
                    '&:hover': {
                        background: Boolean(likeCounter?.has_like) ? 'rgba(255, 211, 74,0.5)' : 'rgba(79,76,76,0.2)',
                    },
                    // '@media not all and (hover: none)': {
                    //     background: !Boolean(likeCounter?.has_like) ? 'rgba(86,84,212,0.5)' : 'rgba(79,76,76,0.2)',
                    // }
                }}>
                    <Like/>
                </IconButton>

                <p>{likeCounter?.count_like}</p>
            </div>

            <div className={s.share}>
                <h1 className={s.share_title}>Поделится</h1>

                <div className={s.content_social}>
                    <a href={share_vk_link} target={'_blank'}>
                        <div className={s.social}><VK/></div>
                    </a>
                    <a href={share_telegram_link} target={'_blank'}>
                        <div className={s.social}><Telegram/></div>
                    </a>
                </div>
            </div>
        </div>
    );
};

export default LikeSocial;
