import React from 'react';
import s from "../modal_work_us/styles.module.css";
import classNames from "classnames";
import {ReactComponent as Icon} from "../../../assets/star.svg";
import {ReactComponent as Close} from "../../../assets/close.svg";
import CustomSelect from "../../select";
import Input from "../../input";
import InputMaskNumber from "../../input_mask";
import Button from "../../button";
import FooterModal from "../../custom_modal/footer_modal";
import TextArea from "../../textarea";
import {Formik} from "formik";
import axios from "axios";
import {toast} from "react-toastify";

const options = [
    {value: 1, label: 'Электроника'},
    {value: 2, label: 'Бытовая техника'},
    {value: 3, label: 'Модные аксессуары'},
]

const ModalStaySupplier = ({onClose}) => {
    const handleSend = async (values, setSubmitting) => {
        setSubmitting(true)
        try {
            const res = await axios.post(`https://itembuy.tech/public/api/send-vendor`, {
                category: values.category.label,
                organization: values.organization,
                contact_person: values.contact_person,
                phone: values.phone,
                brands: values.brands,
                email: values.email,
            })
            setSubmitting(false)
            onClose()
            toast.success('Ваша заявка отправлена!', {
                position: "top-center",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "colored",
            });

        } catch (e) {
            console.log(e)
            setSubmitting(false)
        }
    }
    return (
        <div>
            <div className={s.header}>
                <div className={classNames(s.close, s.close_fake)}/>
                <div className={s.icon}>
                    <Icon/>
                </div>
                <div className={s.close} onClick={onClose}>
                    <Close/>
                </div>
            </div>

            <div className={s.header_title_box}>
                <h3 className={s.title}>Станьте поставщиком <br/> ITEMBUY</h3>
                <p className={s.sub_title}>Если вы хотите продавать свой товар на ITEMBUY заполните форму и мы
                    обязательно свяжемся с вами</p>
            </div>

            <Formik
                enableReinitialize={true}
                initialValues={{
                    category: '',
                    organization: '',
                    contact_person: '',
                    phone: '',
                    brands: '',
                    email: '',
                }}
                validate={values => {
                    const errors = {};
                    if (!values.email) {
                        errors.email = 'Обязательное поле';
                    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)) {
                        errors.email = 'Неверный email адресс';
                    }
                    if (!values.phone) {
                        errors.phone = 'Обязательное поле';
                    } else if (values.phone.length !== 18) {
                        errors.phone = 'Неверный номер телефона';
                    }
                    if (!values.organization) {
                        errors.organization = 'Обязательное поле';
                    }
                    if (!values.category) {
                        errors.category = 'Обязательное поле';
                    }
                    if (!values.contact_person) {
                        errors.contact_person = 'Обязательное поле';
                    }
                    if (!values.brands) {
                        errors.brands = 'Обязательное поле';
                    }
                    return errors;
                }}
                onSubmit={(values, {
                    setSubmitting,
                }) => handleSend(values, setSubmitting)}>
                {({values, handleChange, touched, handleSubmit, errors, isSubmitting, setFieldValue}) => {

                    return <form onSubmit={handleSubmit} className={s.action}>
                        <CustomSelect placeholder={'Выберите категории'}
                                      errorMessage={errors.category && touched.category && errors.category}
                                      options={options}
                                      name={'category'}
                                      value={values.category}
                                      onChange={(value) => {
                                          setFieldValue('category', value)
                                      }}/>
                        <Input value={values.organization}
                               errorMessage={errors.organization && touched.organization && errors.organization}
                               name={'organization'}
                               placeholder={'Наименование организации'}
                               onChange={handleChange}/>

                        <Input value={values.contact_person} name={'contact_person'}
                               placeholder={'Контактное лицо / ФИО'}
                               errorMessage={errors.contact_person && touched.contact_person && errors.contact_person}
                               onChange={handleChange}/>

                        <Input value={values.email} name={'email'} placeholder={'Email'}
                               errorMessage={errors.email && touched.email && errors.email}
                               onChange={handleChange}/>

                        <InputMaskNumber
                            errorMessage={errors.phone && touched.phone && errors.phone}
                            value={values.phone} name={'phone'} placeholder={'+7 000 000-00-00'}
                            mask="+7 (999) 999-99-99"
                            onChange={handleChange}/>

                        <TextArea value={values.brands} name={'brands'}
                                  errorMessage={errors.brands && touched.brands && errors.brands}
                                  placeholder={'Представителем каких брендов вы являетесь?'}
                                  onChange={handleChange}/>

                        <div className={s.btn}>
                            <Button disabled={isSubmitting} type={'submit'}
                                    style={{marginTop: '24px'}}>Отправить</Button>
                        </div>
                    </form>
                }}
            </Formik>

            <FooterModal/>
        </div>
    );
};

export default ModalStaySupplier;
