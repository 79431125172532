import React from 'react';
import s from './styles.module.css'
import Wrapper from "../wrapper";
import Logo from "../../assets/logo";
import LogoText from "../../assets/logo_text";
import {NavLink, useNavigate} from "react-router-dom";
import AnchorLink from "react-anchor-link-smooth-scroll/lib/anchor-link";

const Header = () => {
    const navigate = useNavigate()

    return (
        <div className={s.header}>
            <AnchorLink href='#subscribe' className={s.anchor} id={'anchor'}/>
            <Wrapper>
                <div className={s.header_content}>
                    <div className={s.logo_box}>
                        <Logo className={s.logo} onClick={() => navigate('/')}/>
                        <LogoText style={{marginLeft: '14px'}} className={s.logo_text} onClick={() => navigate('/')}/>
                        <p className={s.description}>Онлайн-аукцион</p>
                    </div>
                    <NavLink to={'/'} onClick={(e) => {
                        setTimeout(() => {
                            const anchor = document.getElementById('anchor')
                            anchor.click()
                        }, 100)

                    }}>

                        <div className={s.btn}>Подписаться</div>

                    </NavLink>
                </div>
            </Wrapper>
        </div>
    );
};

export default Header;
