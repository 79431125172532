import React from 'react';

const Logo = ({style,className,onClick}) => {
    return (
        <svg onClick={onClick} className={className} style={{...style}} width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M0 24C0 12.6863 0 7.02944 3.51472 3.51472C7.02944 0 12.6863 0 24 0C35.3137 0 40.9706 0 44.4853 3.51472C48 7.02944 48 12.6863 48 24C48 35.3137 48 40.9706 44.4853 44.4853C40.9706 48 35.3137 48 24 48C12.6863 48 7.02944 48 3.51472 44.4853C0 40.9706 0 35.3137 0 24Z"
                fill="#FFD34A"/>
            <path
                d="M21.5858 10.4142L10.4142 21.5857C9.63317 22.3668 9.63317 23.6331 10.4142 24.4142L15.5858 29.5857C16.3668 30.3668 17.6332 30.3668 18.4142 29.5857L22.5858 25.4142L33 35.8284L35.8284 32.9999L25.4142 22.5857L29.5858 18.4142C30.3668 17.6331 30.3668 16.3668 29.5858 15.5857L24.4142 10.4142C23.6332 9.63311 22.3668 9.63311 21.5858 10.4142Z"
                fill="#222C3E"/>
        </svg>

    );
};

export default Logo;
