import React from 'react';
import HeaderMain from "./header_main";
import Blogs from "./blogs";

const Main = () => {
    return (
        <>
            <HeaderMain/>
            <Blogs/>
        </>
    );
};

export default Main;
