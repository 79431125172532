import './App.css';
import Header from "./components/header";
import Footer from "./components/footer";
import Router from "./routes/router";
import {useLocation} from "react-router-dom";
import React, {useEffect} from "react";
import AnchorLink from "react-anchor-link-smooth-scroll";
import s from "./components/header/styles.module.css";
import axios from "axios";

function App() {
    const location = useLocation()

    useEffect(() => {
        const getRSCF = async () => {
            try {
                const res = await axios.get('http://185.46.10.53/auth/csrf/')
            } catch (e) {
                console.log(e)
            }
        }
        getRSCF()
    })

    useEffect(() => {
        window.scrollTo({top: 0, behavior: 'smooth'});
    }, [location.pathname])

    useEffect(() => {
        if (location.state?.anchor === 'blogs') {
            const anchor = document.getElementById('anchor_2')
            anchor?.click()
        }
    }, [location.state])
    return (
        <div className={'app'}>

            <AnchorLink href='#blogs' className={s.anchor} id={'anchor_2'}/>
            <Header/>
            <Router/>
            <Footer/>
        </div>
    );
}

export default App;
