import React from 'react';
import {Box, Modal} from "@mui/material";

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    maxWidth: '440px',
    width: '100%',
    maxHeight: '95%',
    overflow: 'auto',
    padding: '24px',
    background: ' #FFFFFF',
    borderRadius: '16px',
};
const CustomModal = ({children, width = '440px', open, onClose}) => {
    return (
        <Modal
            sx={{
                zIndex: 99999999
            }}
            open={open}
            onClose={onClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box sx={{
                ...style, maxWidth: width,
                '@media (max-width: 460px)': {
                    width: '95%'
                }
            }}>
                {children}
            </Box>
        </Modal>
    );
};

export default CustomModal;
