import img1 from '../../../assets/blog_img/img1.png'
import img2 from '../../../assets/blog_img/img2.png'
import img3 from '../../../assets/blog_img/img3.png'
import img4 from '../../../assets/blog_img/img4.png'
import img5 from '../../../assets/blog_img/img5.png'

export const mock = [
    {id: 1, img: img1, title: 'ITEMBUY - идея создания и миссия сервиса онлайн-аукционов', date: '09 августа 2023'},
    {
        id: 2,
        img: img2,
        title: 'Как мы переосмыслили традиционные аукционы в технологичное IT-решение',
        date: '08 августа 2023'
    },
    {id: 3, img: img3, title: 'Основные принципы и цели сервиса', date: '07 августа 2023'},
    {
        id: 4,
        img: img4,
        title: 'Выгода до 60% от рекомендованной стоимости товаров и другие преимущества сервиса',
        date: '06 августа 2023'
    },
    {
        id: 5,
        img: img5,
        title: 'Отвечаем на частые вопросы: что такое онлайн-аукционы, зачем в них участвовать и в чем выгода?',
        date: '05 августа 2023'
    },
]
