import React, {useState} from 'react';
import s from './styles.module.css'

const Input = ({onChange, value, onBlur, placeholder, style, errorMessage = '', name}) => {
    return (
        <div className={s.input}>
            <input style={style}
                   name={name}
                   value={value}
                   onBlur={onBlur}
                   placeholder={placeholder}
                   onChange={onChange}/>
            <span className={s.errorMessage}>{errorMessage}</span>
        </div>

    );
};

export default Input;
